import React from "react";

import {connect} from "react-redux";
import SingleSearchResult from "./single-search-result";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import {fetchPaginationProducts} from "../../../_actions/search.action";
import "./test.css";
import i18next from "i18next";

class SearchResults extends React.Component {
  // onChangePage = data => {
  //   this.setState({
  //     pageLimit: data.pageLimit,
  //     totalPages: data.totalPages,
  //     currentPage: data.page,
  //     startIndex: data.startIndex,
  //     endIndex: data.endIndex
  //   });
  // };

  constructor(props) {
    super(props);
    this.state = {
      products: null,
      filter: "",
    };
  }

  filterContent(letter) {
    this.setState({filter: letter});
    this.props.fetchPaginationProducts(letter);
  }

  handleClick(e) {
  }

  searchmore() {
    localStorage.setItem("pdt", JSON.stringify(this.props.products));
    var id = localStorage.getItem("searchData");
    var index = localStorage.getItem("index");
    index = Number(index) + 1;
    localStorage.setItem("index", index);
    this.props.fetchPaginationProducts(index);
  }

  render() {
    const {products, pagination, paginate, loader} = this.props;
    if (typeof products === "object" && this.state.products === null)
      this.setState({products: products});

    let filterSelect = this.props.filter;
    if (localStorage.getItem("searchType") !== null) {
      console.log("--------->");
      filterSelect = localStorage.getItem("searchType");
    }
    const pageNumbers = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ];
    const pageNumberList = [];

    var paginationList = 0;
    if (products[0] !== undefined) {
      var pdt = products[0];
      if (pdt.totalRecord !== undefined) {
        for (let i = 1; i <= Math.ceil(pdt.totalRecord / 10); i++) {
          pageNumberList.push(i);
        }
      }
    }

    return (
      <React.Fragment>
        {this.props.filter === "Internal" && (
          <div class="row" style={{display: 'none'}}>
            <div class="center-block">
              <nav>
                <ul className="pagination">
                  {pageNumbers.map((number) => (
                    <li key={number} className="page-item">
                      <a
                        href="#"
                        onClick={() => this.filterContent(number)}
                        className="page-link"
                      >
                        {number}
                      </a>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
          </div>
        )}

        {(this.props.filter === "Internal" || this.props.filter === "ProductRec") && (
          <div>
            {products
              .filter(
                (item) =>
                  filterSelect.length === 0 || item.type === filterSelect || item.type === "ProductRec" && item.title.startsWith(this.state.filter)
              )
              .map((result) => (
                <SingleSearchResult result={result}/>
              ))}
          </div>
        )}

        {this.props.filter === "External" && (
          <div>
            {
              products
              .filter((v,i,a)=>a.findIndex(v2=>(v.description === v2.description))===i)
              .filter(
                (item) =>
                  filterSelect.length === 0 || item.type === filterSelect
              )
              .map((result) => (
                <SingleSearchResult result={result}/>
              ))}
          </div>
        )}
        <div className="row">
          <div class="col-12" style={{textAlign: "center"}}>
            <center>
              <button
                onClick={() => this.searchmore()}
                className="btn"
                style={{border: "1px solid #ccc"}}
              >
                <b>{i18next.t("showmoreresult")}</b>
              </button>
            </center>
          </div>
        </div>
        {/* <div class="row">
          <div class="center-block">
            <Pagination
              resultsPerPage={pagination.pageLimit}
              totalResults={10}
              paginate={paginate}
            />
            <nav>
            <ul className='pagination'>
              {pageNumberList.map(number => (
                <li key={number} className='page-item'>
                  <a href="#" onClick={() => this.filterContent(number)} className='page-link'>
                    {number}
                  </a>
                </li>
              ))}
            </ul>
          </nav>
          </div>
        </div> */}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  products: state.product.products,
  pagination: state.product.pagination,
  filter: state.product.filter,
});

export default connect(mapStateToProps, {fetchPaginationProducts})(
  SearchResults
);
